<template>
  <div>
    <div style="margin-bottom: 10px">
      <b-input-group class="">
        <b-form-input
          v-model="search"
          ref="name_input"
          debounce="500"
          class="d-inline-block search-bg"
          :placeholder="$t('device.searchPlaceholder')"
        ></b-form-input>
        <b-input-group-append class="input-prefix-img search-clear"
          ><feather-icon
            icon="XIcon"
            size="24"
            @click="
              (e) => {
                search = '';
              }
            "
          />
        </b-input-group-append>
      </b-input-group>
    </div>
    <div
      style="margin-top: 40px"
      v-if="
        !showLoading &&
        totalUnits === 0 &&
        !search &&
        checkAbility({
          action: constants.PERMISSIONS_ACTION.ADD,
          subject: constants.PERMISSIONS_MODEL.UNITS
        })
      "
    >
      <div>
        <h5>
          {{ $t("unit.EmptyUnitCreateMessage") }}
        </h5>
      </div>

      <div style="text-align: center">
        <b-button
          v-if="
            checkAbility({
              action: constants.PERMISSIONS_ACTION.ADD,
              subject: constants.PERMISSIONS_MODEL.UNITS
            })
          "
          variant="primary"
          :to="{
            name: 'unit-creation',
            query: { allocate_to: selectedAccount }
          }"
        >
          <feather-icon icon="PlusIcon" size="12" class="add-icon-s" />
          <span class="d-none d-sm-inline">{{ $t("device.CreateUnit") }}</span>
        </b-button>
      </div>
    </div>
    <div style="margin-top: 80px" v-else-if="isEmpty">
      {{ $t("NoMatchingRecordsFound") }}
    </div>
    <div class="scroll-config-unit-list">
      <div
        v-for="parent in parents"
        :key="parent.id"
        class="align-items-center b-card unit-ls-data border-primary"
      >
        <div class="parent-unit" @click="toggleChildren(parent)">
          <b-media no-body class="media-card d-flex">
            <b-form-checkbox
              v-model="parent.checked"
              @change="confirmationPopup(parent, $event)"
              @click.native.stop
            >
            </b-form-checkbox>
            <b-media-aside class="mr-75 marginLeftImg unit-url">
              <b-avatar
                style="padding-left: 2px"
                :src="parent.icon"
                variant="primary"
                circle
                size="35"
              />
            </b-media-aside>
            <b-media-body class="my-auto mb-body cursor-pointer">
              <h6 class="mb-0 marginLeftImg text-col-unit">
                {{ parent.name }}
              </h6>
            </b-media-body>
            <feather-icon
              :icon="parent.isExpanded ? 'ChevronDownIcon' : 'ChevronRightIcon'"
              size="24"
              class="toggle-icon"
            />
          </b-media>
        </div>
        <transition name="fade-slide">
          <div v-if="parent.isExpanded">
            <div
              class="child-unit"
              v-for="device in parent.devices"
              :key="device.device_id"
            >
              <b-media no-body class="media-card d-flex">
                <b-form-checkbox
                  v-model="device.checked"
                  @change="confirmationDevicePopup(parent, device, $event)"
                >
                </b-form-checkbox>
                <b-media-aside class="mr-75 marginLeftImg unit-url">
                  <b-avatar
                    circle
                    size="35"
                    variant="light-primary"
                    :text="getInitials(device.device_name)"
                  />
                </b-media-aside>
                <b-media-body
                  class="my-auto mb-body cursor-pointer"
                  @click="
                    (device.checked = !device.checked),
                      confirmationDeviceNamePopup(
                        parent,
                        device,
                        device.checked
                      )
                  "
                >
                  <h6 class="mb-0 marginLeftImg text-col-unit">
                    {{ device.device_name }}
                  </h6>
                </b-media-body>
              </b-media>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <b-modal
      :id="`info-modal${config_key}`"
      centered
      no-close-on-esc
      no-close-on-backdrop
      :hide-header="true"
      size="sm"
      modal-class="no-header-modal"
    >
      <b-card-text>
        <div class="d-flex justify-content-center">
          <div class="d-flex justify-content-center">
            <b-avatar variant="danger" size="96" class="size-96">
              <feather-icon size="56" icon="InfoIcon" />
            </b-avatar>
          </div>
        </div>

        <div class="you-invite">
          {{ $t("configurator.updateConfigurationWarring") }}
        </div>

        <div class="d-flex justify-content-center mb-64 mtt-50">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="applyConfiguration"
          >
            {{ $t("Ok") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click="cancelConfiguration"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </b-card-text>
    </b-modal>
    <Loader :show="showLoading" />
  </div>
</template>

<script>
import {
  BMedia,
  BMediaBody,
  BAvatar,
  BFormCheckbox,
  BMediaAside,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BModal,
  BCardText
} from "bootstrap-vue";
import UtilityService from "@/libs/api/utility-service";
import EventBus from "@/EventBus";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Loader from "@/layouts/components/Loader.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BMedia,
    BMediaBody,
    BAvatar,
    BFormCheckbox,
    BMediaAside,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    Loader,
    BModal,
    BCardText
  },
  directives: {
    Ripple
  },
  data() {
    return {
      parents: [],
      deviceIds: [],
      selectedParent: {},
      search: "",
      timeoutId: null,
      totalUnits: 0,
      showLoading: false,
      isEmpty: false,
      selectedAccount: localStorage.getItem("USER_ACCOUNT_ID")
    };
  },
  props: ["config_key"],
  watch: {
    search(val) {
      this.getPolyMobileUnits(val);
    }
  },
  mounted() {
    this.getPolyMobileUnits();
  },
  methods: {
    toggleChildren(unit) {
      unit.isExpanded = !unit.isExpanded;
    },
    confirmationPopup(parent, isClick) {
      if (isClick) {
        this.selectedParent = {
          parent: parent,
          config_key: this.config_key,
          key: "unit",
          device: {}
        };
        console.log(parent);

        this.$bvModal.show(`info-modal${this.config_key}`);
      } else {
        this.selectedParent = {};
        //  this.toggleCheckbox(parent);
        this.parents = this.parents.map((p) => {
          if (p.id === parent.id) {
            return {
              ...p,
              checked: false,
              devices: p.devices.map((d) => ({ ...d, checked: false }))
            };
          }
          return p;
        });

        this.applyConfig(parent, "unit");
        this.selectedParent = {};
        this.$bvModal.hide(`info-modal${this.config_key}`);
      }
    },
    applyConfiguration() {
      if (
        this.config_key === this.selectedParent.config_key &&
        this.selectedParent.key === "unit"
      ) {
        this.toggleCheckbox(this.selectedParent.parent);
      } else if (
        this.config_key === this.selectedParent.config_key &&
        this.selectedParent.key === "device"
      ) {
        this.checkParentStatus(
          this.selectedParent.parent,
          this.selectedParent.device
        );
      }
      this.selectedParent = {};
      this.$bvModal.hide(`info-modal${this.config_key}`);
    },
    cancelConfiguration() {
      //  this.toggleCheckbox(parent);
      if (this.selectedParent?.key === "unit") {
        this.parents = this.parents.map((p) => {
          if (p.id === this.selectedParent.parent.id) {
            return {
              ...p,
              checked: false,
              devices: p.devices.map((d) => ({ ...d, checked: false }))
            };
          }
          return p;
        });
      } else if (this.selectedParent?.key === "device") {
        this.parents = this.parents.map((parent) => {
          if (parent.id === this.selectedParent.parent.id) {
            parent.devices = parent.devices.map((d) => {
              if (d.device_id === this.selectedParent.device.device_id) {
                d.checked = false;
              }
              return d;
            });
            parent.checked = false;
            // Update parent's checked status based on all devices
          }
          return parent;
        });
      }

      this.selectedParent = {};
      this.$bvModal.hide(`info-modal${this.config_key}`);
    },
    toggleCheckbox(unit) {
      this.parents = this.parents.map((e) => {
        if (unit.id == e.id) {
          e.devices.forEach((device) => {
            device.checked = unit.checked;
          });
        }
        return e;
      });
      this.applyConfig(unit, "unit");
      //EventBus.$emit("get-all-device", this.parents);
    },
    applyConfig(value, key) {
      const allDeviceIds = this.getCheckedDeviceIds(this.parents, value, key);
      this.deviceIds = [...new Set(allDeviceIds)];
      this.$emit("isDisabled", true);
    },
    async getConfig(id) {
      try {
        this.showLoading = true;
        const response = await new UtilityService().getConfig({
          id: id
        });
        if (response?.data) {
          if (!Object.keys(response.data.data).length) {
            return;
          }
          EventBus.$emit("set-device-config", response.data.data);
        }
        this.showLoading = false;
      } catch (err) {
        this.showLoading = false;

        this.showErrorToast(err.message || "Error fetching templates");
      }
    },
    showErrorToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "InfoIcon",
          variant: "danger"
        }
      });
    },
    getCheckedDeviceIds(data, value, key) {
      const checkedDeviceIds = [];

      data.forEach((unit) => {
        unit.devices.forEach((device) => {
          if (device.checked === true) {
            checkedDeviceIds.push(device.device_id);
            if (
              value &&
              key === "device" &&
              value.device_id == device.device_id &&
              value.checked
            ) {
              this.getConfig(device.device_id);
            } else if (key === "unit" && value && value.checked) {
              this.getConfig(device.device_id);
            }
          }
        });
      });

      return checkedDeviceIds;
    },
    getInitials(name) {
      return name[0];
    },
    checkDeviceName(parent, device, isClick) {
      this.applyConfig(device, "device");
    },
    confirmationDevicePopup(parent, device, isClick) {
      if (isClick) {
        this.selectedParent = {
          parent: parent,
          device: device,
          config_key: this.config_key,
          key: "device"
        };
        console.log(parent);

        this.$bvModal.show(`info-modal${this.config_key}`);
      } else {
        this.selectedParent = {};
        //  this.toggleCheckbox(parent);
        const parents = this.parents.map((p) => {
          if (p.id === parent.id) {
            p.devices = p.devices.map((d) => {
              if (d.device_id === device.device_id) {
                d.checked = false;
              }
              return d;
            });
            p.checked = false;
            // Update parent's checked status based on all devices
          }
          return p;
        });

        this.parents = parents;
        this.selectedParent = {};
        this.applyConfig(device, "device");
        this.$bvModal.hide(`info-modal${this.config_key}`);
      }
    },
    confirmationDeviceNamePopup(parent, device, isClick) {
      if (isClick) {
        this.selectedParent = {
          parent: parent,
          device: device,
          config_key: this.config_key,
          key: "device"
        };
        console.log(parent);

        this.$bvModal.show(`info-modal${this.config_key}`);
      } else {
        this.selectedParent = {};
        //  this.toggleCheckbox(parent);
        const parents = this.parents.map((p) => {
          if (p.id === parent.id) {
            p.devices = p.devices.map((d) => {
              if (d.device_id === device.device_id) {
                d.checked = false;
              }
              return d;
            });
            p.checked = false;
            // Update parent's checked status based on all devices
          }
          return p;
        });

        this.parents = parents;
        this.selectedParent = {};
        this.applyConfig(device, "device");
        this.$bvModal.hide(`info-modal${this.config_key}`);
      }
    },
    checkParentStatus(unit, device) {
      unit.checked = unit.devices.every((device) => device.checked);
      this.applyConfig(device, "device");
    },
    searchFilter() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getPolyMobileUnits(this.search);
      }, 300); // Adjust the delay as necessary
    },
    async getPolyMobileUnits(data) {
      try {
        let response = {};
        this.showLoading = true;
        const filter = data ? { filter: data } : {};
        if (this.config_key === "unit") {
          response = await new UtilityService().getPolyMobileUnits({
            ...filter
          });
        } else {
          response = await new UtilityService().getPolyMobileLabels({
            key: "label"
          });
        }

        if (
          response &&
          response.data &&
          response.data.list &&
          response.data.list.length
        ) {
          this.parents = response.data.list.map((parent) => ({
            ...parent,
            isExpanded: false,
            checked: false,
            devices: parent.devices.map((device) => ({
              ...device,
              checked: false
            }))
          }));
          this.totalUnits = response.data.pagination.total_records;
          this.applyConfig(null);
          this.isEmpty = false;
        } else {
          this.parents = [];
          this.isEmpty = true;
        }
        this.showLoading = false;
      } catch (err) {
        this.showLoading = false;
        console.error("Error fetching data:", err);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.unit-ls-data {
  box-shadow: none;
  border: 1px solid var(--gray-605);
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 12px;
  position: relative;
  .media-aside {
    .b-avatar {
      .b-avatar-img {
        padding: 0px;
        img {
          object-fit: contain;
        }
      }
    }
  }
  .b-avatar.badge-secondary {
    background-color: var(--gray-605);
    border-radius: 50% !important;
    margin-left: 6px;
  }
  .no-gsm-text {
    padding-top: 0px;
    margin-left: 10px;
    margin-right: 4px;
    .signal-icon.mr-75 {
      margin-right: 0px !important;
    }
    .gsm-text {
      display: none;
    }
  }
  .media-card {
    width: 100%;
    padding-top: 0px;
  }
  .unit-account-name {
    font-size: 10px;
    margin-top: 4px;
  }
  .unit-account-info {
    text-align: right;
    position: relative;
    .info-icon-activity {
      position: absolute;
      right: 0;
      bottom: -10px;
    }
  }
  .parent-unit {
    // padding: 0px 0px 12px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .child-unit {
    padding: 0px 12px 12px 18px;
  }
  .child-unit:first-child {
    padding-top: 12px;
  }
}
.scroll-config-unit-list {
  max-height: calc(100vh - 220px);
  min-height: calc(100vh - 220px);
  overflow-y: auto;
}
.dark-layout {
  .unit-ls-data {
    .b-avatar.badge-secondary {
      background-color: var(--light);
    }
  }
}
.bottom-pagination {
  text-align: center;
  display: flex;
}
/* Animation */
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.5s ease-in-out;
}
.fade-slide-enter {
  opacity: 0;
  transform: translateY(-10px);
}
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
.toggle-icon {
  transition: transform 0.5s ease;
}
</style>
