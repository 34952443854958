<template>
  <div
    class="customizer unit-filter-dashboard"
    :class="{ open: isCustomizerOpen }"
  >
    <b-link
      class="customizer-toggle d-flex align-items-center justify-content-center"
      @click="toggleCustomizer"
    >
      <slidersIcon />
    </b-link>
    <div class="close-btn">
      <feather-icon
        icon="XIcon"
        size="24"
        class="cursor-pointer"
        @click="toggleCustomizer"
      />
    </div>

    <div class="filter-tab">
      <b-tabs>
        <b-tab :title="$t('configurator.Units')" active>
          <div class="tab-outer">
            <ConfigUnitList
              @isDisabled="isDisabled"
              config_key="unit"
              ref="unit"
            />
          </div>
        </b-tab>
        <b-tab :title="$t('configurator.Groups')">
          <div class="tab-outer">
            <ConfigUnitList
              @isDisabled="isDisabled"
              config_key="group"
              ref="group"
            />
          </div>
        </b-tab>
      </b-tabs>
      <div class="text-center">
        <b-button
          variant="primary"
          @click="applyDevice"
          class="btn-icon mt-1"
          v-if="
            checkAbility({
              action: constants.PERMISSIONS_ACTION.SEND_CONFIGURATOR,
              subject: constants.PERMISSIONS_MODEL.TEMPLATES
            })
          "
          :disabled="disabled"
        >
          {{ $t("configurator.Apply_Configuration") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BButton, BTabs, BTab } from "bootstrap-vue";
import useAppCustomizer from "@/@core/layouts/components/app-customizer/useAppCustomizer";
import EventBus from "@/EventBus";
import slidersIcon from "@/assets/images/icons/slidersIcon.svg";
import ConfigUnitList from "@/layouts/components/Config/ConfigUnitList.vue";
import { apply } from "mathjs";
export default {
  components: { BLink, BButton, BTabs, BTab, slidersIcon, ConfigUnitList },
  setup() {
    const { isCustomizerOpen } = useAppCustomizer();
    return { isCustomizerOpen };
  },
  data() {
    return {
      disabled: true
    };
  },
  computed: {},
  watch: {
    isCustomizerOpen() {
      EventBus.$emit("is-customizer-open", this.isCustomizerOpen);
    }
  },
  methods: {
    toggleCustomizer() {
      this.isCustomizerOpen = !this.isCustomizerOpen;
    },
    applyDevice() {
      EventBus.$emit("get-all-device", [
        ...this.$refs.group.deviceIds,
        ...this.$refs.unit.deviceIds
      ]);
    },
    isDisabled() {
      this.disabled = [
        ...this.$refs.group.deviceIds,
        ...this.$refs.unit.deviceIds
      ].length
        ? false
        : true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";
.tab-outer {
  padding: 15px;
}
</style>
